define("discourse/plugins/chat/discourse/templates/admin-plugins/show/discourse-chat-incoming-webhooks/edit", ["exports", "ember-route-template", "truth-helpers", "discourse/components/back-button", "discourse/components/conditional-loading-spinner", "discourse/plugins/chat/admin/components/chat-incoming-webhook-edit-form", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _emberRouteTemplate, _truthHelpers, _backButton, _conditionalLoadingSpinner, _chatIncomingWebhookEditForm, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)((0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="admin-detail discourse-chat-incoming-webhooks">
        <BackButton
          @label="chat.incoming_webhooks.back"
          @route="adminPlugins.show.discourse-chat-incoming-webhooks.index"
          class="incoming-chat-webhooks-back"
        />
  
        <ConditionalLoadingSpinner @condition={{not @controller.model.webhook}}>
          <ChatIncomingWebhookEditForm
            @webhook={{@controller.model.webhook}}
            @chatChannels={{@controller.model.chat_channels}}
          />
        </ConditionalLoadingSpinner>
      </div>
    
  */
  {
    "id": "bha8H1u9",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"admin-detail discourse-chat-incoming-webhooks\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"incoming-chat-webhooks-back\"]],[[\"@label\",\"@route\"],[\"chat.incoming_webhooks.back\",\"adminPlugins.show.discourse-chat-incoming-webhooks.index\"]],null],[1,\"\\n\\n      \"],[8,[32,1],null,[[\"@condition\"],[[28,[32,2],[[30,1,[\"model\",\"webhook\"]]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[32,3],null,[[\"@webhook\",\"@chatChannels\"],[[30,1,[\"model\",\"webhook\"]],[30,1,[\"model\",\"chat_channels\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@controller\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/templates/admin-plugins/show/discourse-chat-incoming-webhooks/edit.js",
    "scope": () => [_backButton.default, _conditionalLoadingSpinner.default, _truthHelpers.not, _chatIncomingWebhookEditForm.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, undefined)));
});